import React, { Component } from 'react';
class Menuitem extends Component {
	render() {
		return (
			<div className="row">
				<div className="col-lg-4">
					<ul className="menu-list m-b0">
						<h3 className="menubar"><b>Main Menu</b></h3>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i> Pasta
									</span>
								</h4>
								<div className="display-linebreak menuprice">
									Combo
									$9.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Reg
									$6.99
								</div>
							</div>
							<p className="description">Chicken / Beef / Paneer</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Bun Kebab</span>
								</h4>
								<div className="display-linebreak menuprice">
									Combo
									$9.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Reg
									$6.99
								</div>
							</div>
							<p className="description">Beef</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Vada Pav</span>
								</h4>
								<div className="display-linebreak menuprice">
									Combo
									$10.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Reg
									$7.99
								</div>
							</div>
							<p className="description">Chicken / Beef / Veggie</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>burger</span>
								</h4>
								<div className="display-linebreak menuprice">
									Combo
									$10.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Reg
									$7.99
								</div>
							</div>
							<p className="description">Chicken / Beef / Veggie</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Tortilla or Naan Rolls</span>
								</h4>
								<div className="display-linebreak menuprice">
									Combo
									$10.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Reg
									$7.99
								</div>
							</div>
							<p className="description">Chicken / Beef / Paneer</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Hot Dog</span>
								</h4>
								<div className="display-linebreak menuprice">
									Combo
									$8.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Reg
									$5.99
								</div>
							</div>
							<p className="description">Chicken / Beef / Veggie</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Corn Dogs Bites</span>
								</h4>
								<div className="display-linebreak menuprice">
									Combo
									$8.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Reg
									$5.99
								</div>
							</div>
							<p className="description">Chicken / Beef / Veggie</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Biryani Plate Medium</span>
								</h4>
								<div className="display-linebreak menuprice">
									Combo
									$10.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Reg
									$7.99
								</div>
							</div>
							<p className="description">Chicken / Beef / Paneer</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Biryani Plate Large</span>
								</h4>
								<div className="display-linebreak menuprice">
									Combo
									$14.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Reg
									$11.99
								</div>
							</div>
							<p className="description">Chicken / Beef / Veggie</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Naan/Tortilla Pizza</span>
								</h4>
								<div className="display-linebreak menuprice">
									Combo
									$10.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Reg
									$7.99
								</div>
							</div>
							<p className="description">Choice of any three toppings</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Chicken Wings (6-pack)</span>
								</h4>
								<div className="display-linebreak menuprice">
									Combo
									$9.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Reg
									$6.99
								</div>
							</div>
							<p className="description">Choice of sauce</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Chicken Wings (12-pack)</span>
								</h4>
								<div className="display-linebreak menuprice">
									Combo
									$15.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Reg
									$12.99
								</div>
							</div>
							<p className="description">Choice of sauce</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Plain Poutine</span>
								</h4>
								<div className="display-linebreak menuprice">
									Combo
									$8.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Reg
									$5.99
								</div>
							</div>
							<p className="description">Signature gravy & Cheese-curds</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Gourmet Poutine</span>
								</h4>
								<div className="display-linebreak menuprice">
									Combo
									$10.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Reg
									$7.99
								</div>
							</div>
							<p className="description">Beef / Chicken / Hot Dog / Paneer / Samosa with Signature gravy & Cheese-curds</p>
						</li>
					</ul>
				</div>
				<div className="col-lg-4">
					<ul className="menu-list m-b0">
					<h3 className="menubar"><b>Sides</b></h3>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i> French Fries
									</span>
								</h4>
								<div className="display-linebreak menuprice">
									Large
									$6.50
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Medium
									$4.50
								</div>
							</div>
							<p className="description">Crunchy exterior and a light, fluffy interior</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i> Onion Rings
									</span>
								</h4>
								<div className="display-linebreak menuprice">
									Large
									$6.50
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Medium
									$4.50
								</div>
							</div>
							<p className="description">Cross-sectional "ring" of onion dipped in batter</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i> Samosa Plate
									</span>
								</h4>
								<div className="display-linebreak menuprice">
									Large 
									(5 pieces)
									$6.50
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Medium 
									(3 pieces)
									$4.50
								</div>
							</div>
							<p className="description">Fried pastry filled with potatoes</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i> Chaat
									</span>
								</h4>
								<div className="display-linebreak menuprice">
									Standard
									$6.50
								</div>
							</div>
							<p className="description">Papdi / Samosa with yogurt and various sauces(chutney)</p>
						</li>

					</ul>
				</div>
				<div className="col-lg-4">
					<ul className="menu-list m-b0">
					<h3 className="menubar"><b>Drinks / Desserts</b></h3>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i> Falooda
									</span>
								</h4>
								<div className="display-linebreak menuprice">
									Standard
									$5.99
								</div>&nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									With Icecream
									$6.50
								</div>
							</div>
							<p className="description">Rose / Saffron / Pistachio with glass noodles, jelly, cream and basil seeds</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Rice Pudding</span>
								</h4>
								<div className="display-linebreak menuprice">
									Standard
									$3.99
								</div>
							</div>
							<p className="description">Slowly cooked milk and rice with hint of cardamon</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Cakes Slices</span>
								</h4>
								<div className="display-linebreak menuprice">
									Standard
									$4.99
								</div>
							</div>
							<p className="description">Flavour of the day</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Coffee</span>
								</h4>
								<div className="display-linebreak menuprice">
									Large
									$2.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Medium
									$1.99
								</div>
							</div>
							<p className="description">Filtered coffee</p>
						</li>
						<li>
							<div className="menu-item">
								<h4 className="title">
									<span><i className="la la-check-circle-o"></i>Tea</span>
								</h4>
								<div className="display-linebreak menuprice">
									Large
									$2.99
								</div>  &nbsp;&nbsp;
								<div></div>
								<div className="display-linebreak menuprice">
									Medium
									$1.99
								</div>
							</div>
							<p className="description">Steeped Tea with cardamon or chai masala</p>
						</li>
					</ul>
				</div>

			</div>

		)

	}
}

export default Menuitem;