import React, { Component } from 'react';

import { Link } from 'react-router-dom';

class Header extends Component{
	
	componentDidMount() {

        // sidebar open/close

        var btn = document.querySelector('.navicon');
        var aaa = document.querySelector('.myNavbar ');

        function toggleFunc() {
            return aaa.classList.toggle("show");
        }

        btn.addEventListener('click', toggleFunc);


        // Sidenav li open close
		
        var navUl = [].slice.call(document.querySelectorAll('.navbar-nav > li'));
        for (var y = 0; y < navUl.length; y++) {
            navUl[y].addEventListener('click', function () { checkLi(this) });
        }

        function checkLi(current) {
            navUl.forEach(el => el.classList.remove('open'));
            current.classList.add('open');
        }
	}	
	
	render(){
		
		return(
			<header className="site-header header center mo-left header-style-2">
		
				<div className="sticky-header main-bar-wraper navbar-expand-lg">
					<div className="main-bar clearfix"  style={{background:'orange'}}>
						<div className="container clearfix headercolor">
							
							<div className="logo-header mostion">
								<Link to={'/'} className="dez-page"><h1 className="title">FM</h1></Link>
							</div>
							<button className="navbar-toggler collapsed navicon justify-content-end" type="button" data-toggle="collapse" data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
							
							<div className="header-nav navbar-collapse navbar myNavbar collapse justify-content-between" id="navbarNavDropdown">
								<div className="logo-header mostion">
									<Link to={'/'} className="dez-page"><h1 className="title">FM</h1></Link>
								</div>
								<ul className="nav navbar-nav nav1">	
									<li className="active"><Link to={'/'}>Home</Link>
									</li>
									<li><Link to={'#'}>About Us</Link>
									</li>
									<li><Link to={'/our-menu-1'}>Menu</Link>

									</li>
								</ul>
								<ul className="nav navbar-nav nav2">
									<li className="has-mega-menu"><Link to={'#'}>FAQ</Link>
									</li>

									<li>
										<Link to={'#'}>Place Order</Link>
										{/* <ul className="sub-menu">
											<li><Link to ={'/shop'}>Shop</Link></li>
											<li><Link to ={'/shop-sidebar'}>Shop Sidebar</Link></li>
											<li><Link to ={'/shop-product-details'}>Product Details</Link></li>
											<li><Link to ={'/shop-cart'}>Cart</Link></li>
											<li><Link to ={'/shop-wishlist'}>Wishlist</Link></li>
											<li><Link to ={'/shop-checkout'}>Checkout</Link></li>
											<li><Link to ={'/shop-login'}>Login</Link></li>
											<li><Link to ={'/shop-register'}>Register</Link></li>
										</ul> */}
									</li>
									<li><Link to={'/contact-1'}>Contact Us</Link>


											
											
									</li>
								</ul>		
							</div>
						</div>
					</div>
				</div>
       
			</header>	
		
		)
	}
	
}

export default Header;