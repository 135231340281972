import React, {Component} from 'react';
import Header from './../Layout/Header';
import Footer from './../Layout/Footer';
import {Link} from 'react-router-dom';
import CountUp from 'react-countup';
import OurPartners from './../Element/OurPartners';
import Slider from './../Element/Slider';
import Accord from './../Element/Accord';
	
//Images	
var img1 = require('./../../images/background/bg5.jpg');
var serblog1 = require('./../../images/our-services/pic1.jpg');
var serblog2 = require('./../../images/our-services/pic2.jpg');
var serblog3 = require('./../../images/our-services/pic3.jpg');
var serblog4 = require('./../../images/our-services/pic4.jpg');
var img2 = require('./../../images/background/bg1.jpg');
var img3 = require('./../../images/background/bg5.jpg');
var img4 = require('./../../images/background/bg4.jpg');


const blogNews = [
	{
		image: require('./../../images/blog/grid/pic1.jpg'),
		title: 'Understand The Background Of Bakery Now.',
	},
	{
		image: require('./../../images/blog/grid/pic2.jpg'),
		title: 'Seven Reliable Sources To Learn About Bakery.',
	},
	{
		image: require('./../../images/blog/grid/pic3.jpg'),
		title: 'Ten Places That You Can Find Bakery.',
	},
]


class Index1 extends Component{
	render(){
		return(
			<>
				<Header />
			
				<div className="page-content bg-white">
					<div className="content-block">
						<Slider />
						<div className="section-full content-inner-3" style={{backgroundImage:"url(" + img1 + ")",  backgroundSize:"100%" }}>
							<div className="container">
								<div className="row service-area1">
									<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
										<div className="icon-bx-wraper text-center service-box1" style={{backgroundImage: "url(" + serblog1 + ")"}}>
											<div className="icon-content">
												<h2 className="dlab-tilte text-white">Hot Dogs</h2>
												<p>Global Favourite</p>
												<div className="dlab-separator style1 bg-primary"></div>
												<Link to={('/our-menu-1')} className="btn btnhover">Menu <i className="fa fa-angle-double-right m-l5"></i></Link>
											</div>
										</div>
									</div>
									<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
										<div className="icon-bx-wraper text-center service-box1" style={{backgroundImage: "url(" + serblog2 + ")"}}>
											<div className="icon-content">
												<h2 className="dlab-tilte text-white">Wrap</h2>
												<p>Soft wrap around a filling of your choice</p>
												<div className="dlab-separator style1 bg-primary"></div>
												<Link to={('/our-menu-1')} className="btn btnhover">Menu <i className="fa fa-angle-double-right m-l5"></i></Link>
											</div>
										</div>
									</div>
									<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
										<div className="icon-bx-wraper text-center service-box1" style={{backgroundImage: "url(" + serblog3 + ")"}}>
											<div className="icon-content">
												<h2 className="dlab-tilte text-white">Rice Puddings</h2>
												<p>Creamy and Authentic</p>
												<div className="dlab-separator style1 bg-primary"></div>
												<Link to={('/shop-product-details')} className="btn btnhover">Menu <i className="fa fa-angle-double-right m-l5"></i></Link>
											</div>
										</div>
									</div>
									<div className="col-xl-3 col-lg-3 col-md-6 col-sm-6">
										<div className="icon-bx-wraper text-center service-box1" style={{backgroundImage: "url(" + serblog4 + ")"}}>
											<div className="icon-content">
												<h2 className="dlab-tilte text-white">Samosa</h2>
												<p>Savory filling</p>
												<div className="dlab-separator style1 bg-primary"></div>
												<Link to={('/our-menu-1')} className="btn btnhover">Menu <i className="fa fa-angle-double-right m-l5"></i></Link>
											</div>
										</div>
									</div>
								</div>

							</div>
						</div>					
						<div className="section-full content-inner service-area2 bg-img-fix bg-line-top bg-line-bottom" style={{backgroundImage: "url(" + img4 + ")",  backgroundSize: "cover" }}>
							<div className="container">
								<div className="row">
									<div className="col-lg-12">
										<div className="section-head text-center">
											<h2 className="text-white">What Do We Offer For You?</h2>
											<p className="text-bold">We offer you a unique fusion of flavors from various cultures and regions. Checkout our menu and order today to satisfy the cravings!</p>
											<div className="dlab-separator style1 bg-primary"></div>
										</div>
									</div>
								</div>
								<div className="row">
									<div className="col-lg-4 m-b30">
										<img src={require('./../../images/about/spices.jpg')} className="img-cover radius-sm" alt="" />
									</div>
									<div className="col-lg-8">
										<div className="row p-l30">
											<div className="col-lg-6 col-sm-6 m-b30">
												<div className="icon-bx-wraper text-white service-box2">
													<div className="icon-bx">
														
													</div>
													<div className="icon-content">
														<h5 className="dlab-tilte">Biryani Rice</h5>
														<p>Aromatic rice with subtle balance of flavours</p>
													</div>
												</div>
											</div>
											<div className="col-lg-6 col-sm-6 m-b30">
												<div className="icon-bx-wraper text-white service-box2">
													<div className="icon-bx">

													</div>
													<div className="icon-content">
														<h5 className="dlab-tilte">Pasta</h5>
														<p>Pasta with a hint of curry flavour</p>
													</div>
												</div>
											</div>
											<div className="col-lg-6 col-sm-6 m-b30">
												<div className="icon-bx-wraper text-white service-box2">
													<div className="icon-bx">
													</div>
													<div className="icon-content">
														<h5 className="dlab-tilte">Vada Pav</h5>
														<p>Potato ball bursting with flavours stuffed in Brioche bun</p>
													</div>
												</div>
											</div>
											<div className="col-lg-6 col-sm-6 m-b30">
												<div className="icon-bx-wraper text-white service-box2">
													<div className="icon-bx"> 
													</div>
													<div className="icon-content">
														<h5 className="dlab-tilte">Tortilla / Naan Pizza</h5>
														<p>Simple base with fusion toppings</p>
													</div>
												</div>
											</div>
											<div className="col-lg-6 col-sm-6 m-b30">
												<div className="icon-bx-wraper text-white service-box2">
													<div className="icon-bx"> 
													</div>
													<div className="icon-content">
														<h5 className="dlab-tilte">Almond Milk  Shake</h5>
														<p>Going back to fourty years old recipe</p>
													</div>
												</div>
											</div>
											<div className="col-lg-6 col-sm-6 m-b30">
												<div className="icon-bx-wraper text-white service-box2">
													<div className="icon-bx">  
													</div>
													<div className="icon-content">
														<h5 className="dlab-tilte">Chaat</h5>
														<p>Tingling flavours from tangy, spicy, and sweet sauce</p>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<div className="section-full bg-white">
							<div className="container-fluid">
								<div className="row">
									<div className="col-lg-3 col-md-6 col-sm-6">
										<div className="port-box1 text-white">
											<div className="dlab-media">
												<img src={require('./../../images/our-work/pic1.jpg')} alt="" />
											</div>
											<div className="dlab-info">
												<h2 className="title">Falooda</h2>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-6">
										<div className="port-box1 text-white">
											<div className="dlab-media">
												<img src={require('./../../images/our-work/pic2.jpg')} alt="" />
											</div>
											<div className="dlab-info">
												<h2 className="title">Chai Tea</h2>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-6">
										<div className="port-box1 text-white">
											<div className="dlab-media">
												<img src={require('./../../images/our-work/pic3.jpg')} alt="" />
											</div>
											<div className="dlab-info">
												<h2 className="title">Vada Pav</h2>
											</div>
										</div>
									</div>
									<div className="col-lg-3 col-md-6 col-sm-6">
										<div className="port-box1 text-white m-md-b0 m-sm-b0">
											<div className="dlab-media">
												<img src={require('./../../images/our-work/pic4.jpg')} alt="" />
											</div>
											<div className="dlab-info">
												<h2 className="title">Sandwich</h2>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
						</div>

						</div>
				
				<Footer />
			</>	
		)
	}
} 

export default Index1;